<template>
  <div>
    <AppHeader title="密码找回"/>
    <Panel class="tabs" :title="false">

      <div slot="content">

        <div>
          <div class="form-group mt20" >
            <div class="form-input-item">
              <input class="form-control" style="margin: 10px 0" v-model="pwd1" type="password" placeholder="请填写密码">
              <input class="form-control" v-model="pwd2" type="password" placeholder="请填写密码">
            </div>
            <div>
              <div class="btn dark-blue login-btn" @click="save()">提交</div>
            </div>
          </div>


        </div>
      </div>
    </Panel>
  </div>
</template>
<style lang="scss" scoped>
.identity-login {
  display: flex;
  align-items: center;
  justify-content: center;
}

.identity-login-content-right {
  margin-left: 15px;
  text-align: center;
}

.identity-login .icon-license {
  font-size: 50px !important;
}

.identity-title {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 5px;
}

.identity-sub-title {
  font-size: 15px;
  color: RGBA(174, 174, 174, 1.00);
  margin-top: 5px;
}

.login-panel {
  .panel-title {
    font-size: 24px;
    font-weight: 700;
    color: #1f1f1f;
    margin-bottom: 5px;
  }

  .content {
    margin: 45px 28px;
  }

  .form-control {
    border-radius: 6px;
    border: 2px solid #F5F5F5;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    padding: 15px 15px 15px 45px;
  }
}

.login-title {
  color: #666;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 1px;
  text-align: center;
  font-weight: 500;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 60px;
}

.form-group {
  margin-bottom: 16px;
}

.form-control::placeholder {
  color: #97a4af;
  opacity: 1;
}

.form-control {
  display: block;
  width: 100%;
  height: auto;
  padding: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  color: #212529;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #eeeeff;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.form-input-label {
  display: block;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 400;
}

.form-input-item {
  position: relative;
}

.login-from-title {
  font-size: 22px;
  font-weight: 500;
}

.captcha-code {
  width: 120px;
  margin-left: 10px;
}

.login-bg {
  margin: 0;
  position: absolute;
  top: 0;
  height: 100% !important;
  background: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.login-head {
  background: url("../assets/images/login-head.png");
  background-size: cover;
  height: 221px;
  padding-top: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-holder {
  width: 92px;
  height: 92px;
  background: rgba(255, 255, 255, .3);
  border-radius: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-holder-inner {
  width: 84px;
  height: 84px;
  border-radius: 42px;
  overflow: hidden;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 90%;
}

.logo-project {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: 500;
  color: #FFFFFF;
}

.login-mobile, .login-pwd, .login-captcha {
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  position: absolute;
  left: 16px;
  top: 17px;
}

.login-mobile {
  background-image: url("../assets/images/login-mobile.png");
}

.login-pwd {
  background-image: url("../assets/images/login-pwd.png");
}

.login-captcha {
  background-image: url("../assets/images/login-captcha.png");
}

.login-btn {
  background: #1784A9 !important;
  margin-top: 30px;
}
</style>
<script>
import AppHeader from "@/components/AppHeader";
import Panel from "@/components/Panel";
import {MessageBox, Toast} from 'mint-ui';
import sec from "@/plugins/user";
export default{
  components: {
    AppHeader,
    Panel
  },
  data() {
    return {
      tabIndex: 0,
      phone: '',
      email: '',
      imgBase64: '',
      captcha: '',
      smsCounter:0,
      mobileCode: '',
      pwd1: '',
      pwd2: '',
      reset: false
    }
  },
  methods: {
    deleteCookie(name) {
      document.cookie = name + '=; Max-Age=-1; path=/';
    },
    save(){
      //判断两次密码是否相同
      if (this.pwd1 !== this.pwd2) {
        Toast('两次密码不一致')
        return
      }
      let that = this
      let data = new FormData()
      //获取链接中的token
      let token = window.location.href.split('token=')[1]
      //删除cookie中st数据
      this.deleteCookie('_st')
      data.append('pwd', this.pwd1)
      data.append('pwd1', this.pwd2)
      this.$http.post('/portal/account/resetPwd2?token='+token, data).then(res =>{
        if (res.success){
          Toast('密码重置成功')
          sec.removeUser()
          setTimeout(function (){
            that.$router.push({path: '/login'})
          },1000)
        }

      })
    },

    changeCaptcha() {
      const that = this
      this.$http.get(`/common/captcha?v=${Math.random()}`, {
        responseType: 'arraybuffer'
      }).then(response => new Buffer(response, 'binary').toString('base64'))
          .then(data => {
            that.imgBase64 = `data:image/png;base64,${data}`
          });
    },
  },
  mounted() {
    this.changeCaptcha()
  }
}
</script>
